import React from "react";

const subTestimonies = [
  {
    id: "testi1",
    content:
      "Ketika suatu desain udah masuk sampe ke tahap codingan, itu jadinya berpengaruh banget buat implementasi desainnya secara akurat sampe ke final productnya.",
    author: "Fahrizal Bimantara",
    role: "UI Designer Lead at PaDI UMKM",
    profile_url: "testimony-1.png",
  },
  {
    id: "testi2",
    content:
      "Sekarang ngeliat website Legion dokumentasi componentnya udah rapih, bisa akses di web dan ada aplikasinya testiingnya juga di mobile, udah lolos standar aksesible, terus teamnya juga fast response.",
    author: "Afif Adi Wiratama",
    role: "Head of Design Agree",
    profile_url: "testimony-2.png",
  },
  {
    id: "testi3",
    content:
      "Ketika import component dari Legion, modifiernya lengkap. Sehingga membantu developer buat bereksperimen lebih banyak!",
    author: "M. Ilham Rilambang",
    role: "Mobile Engineer at Antares Eazy",
    profile_url: "testimony-3.png",
  },
  {
    id: "testi4",
    content:
      "Dengan Legion, raising issue jadi lebih lancar. Bisa dapetin component staging yang udah siap pakai, bikin QA (Quality Assurance) jadi lebih simpel berkat standar komponen yang mereka punya. Tim coding kita jadi gampang banget implementasinya.",
    author: "Fadly Tanjung",
    role: "Front End Engineer at Logee",
    profile_url: "testimony-4.png",
  },
];

const SectionTestimony = () => {
  const [isIntersecting, setIsIntersecting] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  React.useEffect(() => {
    if (isIntersecting) {
      ref.current
        .querySelectorAll(".landing-testimony__secondary__testimony__item")
        .forEach((el) => {
          el.classList.add("slide-in");
        });
      ref.current.querySelector(".landing-testimony__primary__testimony").classList.add("slide-in");
    }
  }, [isIntersecting]);

  return (
    <section className="landing-testimony">
      <div className="landing-container" ref={ref}>
        <div className="landing-testimony__heading">
          <h2>Experts Say About Legion Design System</h2>
          <svg
            width="40"
            height="34"
            viewBox="0 0 40 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M31.9703 17.5713C32.5266 21.9057 34.0141 25.7165 36.3907 29.0277C36.511 29.1934 36.4845 29.4199 36.3329 29.5543L32.1593 33.2559C31.9968 33.3996 31.7468 33.3856 31.6015 33.2246C26.1107 27.1138 22.2671 20.9326 22.2671 12.4166V1.06822C22.2671 0.835404 22.4577 0.644775 22.6906 0.644775H39.5766C39.8094 0.644775 40 0.8354 40 1.06822V17.1478C40 17.3806 39.8094 17.5713 39.5766 17.5713L31.9703 17.5713Z"
              fill="#212121"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.70158 17.5713C10.2578 21.9057 11.7453 25.7165 14.122 29.0277C14.2407 29.1934 14.2157 29.4199 14.0626 29.5543L9.889 33.2559C9.7265 33.3996 9.47652 33.3856 9.33276 33.2246C3.84076 27.1138 -0.00323868 20.9326 -0.00323868 12.4166V1.06822C-0.00323868 0.835404 0.187386 0.644775 0.420202 0.644775H17.3062C17.5406 0.644775 17.7312 0.8354 17.7312 1.06822V17.1478C17.7312 17.3806 17.5406 17.5713 17.3062 17.5713L9.70158 17.5713Z"
              fill="#212121"
            />
          </svg>
        </div>

        <hr />

        <div className="landing-testimony__primary__testimony">
          <h3 className="landing-testimony__primary__testimony__text">
            “Designer bisa lebih fokus terkait flow dan experience. Enggak
            pusing lagi ngurusin komponen dan sebagainya karena sudah diprovide
            Legion secara lengkap. Terus yang paling disuka, asetnya bisa di
            costumize sesuai kebutuhan masing - masing produk.”
          </h3>
          <div className="landing-testimony__primary__testimony__subtitle">
            <img
              loading="lazy"
              src="/annual-report-2023/testimony-0.png"
              alt="author-picture-Taufan Novembri"
            />
            <div className="landing-testimony__primary__testimony__details">
              <p>Taufan Novembri</p>
              <span>UI Designer MyTens</span>
            </div>
          </div>
        </div>

        <div className="landing-testimony__secondary__testimony">
          {subTestimonies.map((testimonyItem, testimonyIdx) => (
            <div
              key={testimonyIdx}
              className="landing-testimony__secondary__testimony__item"
            >
              <hr />
              <h4>"{testimonyItem.content}"</h4>
              <div className="landing-testimony__secondary__testimony__item__details">
                <img
                  loading="lazy"
                  src={`/annual-report-2023/${testimonyItem.profile_url}`}
                  alt={`author-picture-${testimonyItem.author}`}
                />
                <p>{testimonyItem.author}</p>
                <span>{testimonyItem.role}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="landing-testimony__decoration"></div>
    </section>
  );
};

export default SectionTestimony;
